<template>
  <v-container class="py-0 py-sm-8 px-0 px-sm-4">
    <v-row no-gutters>
      <!-- <v-col cols="12" class="col-sm-3">
        <v-card class="d-flex align-center justify-center pa-4 fill-width fill-height indigo" dark outlined @click.prevent="TRX_SHOW()">
          <v-icon small class="mr-2">
            mdi-plus
          </v-icon>
          New Transaction
        </v-card>
      </v-col> -->
      <v-col cols="6" class="col-sm-8 white">
        <div class="px-3 pt-4 pb-0 pb-sm-4">
          <v-text-field
            v-model="tableSearch"
            outlined
            dense
            hide-details
            class="rounded-0"
            placeholder="Search ..."
            prepend-inner-icon="mdi-magnify"
          />
        </div>
      </v-col>
      <v-col cols="6" class="col-sm-2 white">
        <div class="px-3 pt-4 pb-0 pb-sm-4">
          <v-select
            v-model="tableSearchScheduleType"
            :items="[{ name: 'All Type', value: '' }].concat($store.state.schedule_type)"
            item-text="name"
            item-value="value"
            outlined
            dense
            hide-details
            class="rounded-0"
            label="Schedule Type"
          />
        </div>
      </v-col>
      <v-col cols="12" class="col-sm-2 white">
        <div class="px-3 py-4 pb-sm-4">
          <v-select
            v-model="tableSearchStatus"
            :items="[{ name: 'Semua Status', value: null }, { name: 'Scheduled', value: 1 }, { name: 'Delivery', value: 2 }, { name: 'Completed', value: 3 } ]"
            item-text="name"
            item-value="value"
            outlined
            dense
            hide-details
            class="rounded-0"
            label="Order Status"
          />
        </div>
      </v-col>
      <v-col cols="12" class="white">
        <div class="px-3 pt-0 pb-4">
          <v-menu
            ref="menu"
            v-model="menu"
            :return-value.sync="dates"
            left
            offset-y
            :close-on-content-click="false"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="dateRangeText"
                label="Schedule Ranges"
                append-icon="mdi-calendar-outline"
                readonly
                outlined
                dense
                hide-details
                clearable
                class="rounded-0"
                v-bind="attrs"
                v-on="on"
                @click:clear="dates = []"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="dates"
              no-title
              light
              class="force-light-font"
              range
            >
              <v-spacer />
              <v-btn
                text
                class="text-capitalize"
                small
                @click="menu = false"
              >
                Cancel
              </v-btn>
              <v-btn
                text
                class="text-capitalize"
                small
                @click="$refs.menu.save(dates)"
              >
                Ok
              </v-btn>
            </v-date-picker>
          </v-menu>
        </div>
      </v-col>
      <v-col cols="12" class="pt-0">
        <v-row no-gutters class="py-2 px-0">
          <v-col cols="6" class="col-sm-3 pa-2">
            <v-card class="d-flex flex-column align-center justify-center rounded-0 primary px-4 py-2" dark flat>
              <span class="caption">Quantity</span>
              <div class="font-weight-bold">
                {{ parseInt(tableSummary.quantity) }}
              </div>
            </v-card>
          </v-col>
          <v-col cols="6" class="col-sm-3 pa-2">
            <v-card class="d-flex flex-column align-center justify-center rounded-0 primary px-4 py-2" dark flat>
              <span class="caption">Subtotal</span>
              <div class="font-weight-bold">
                {{ parseInt(tableSummary.subtotal) | price }}
              </div>
            </v-card>
          </v-col>
          <v-col cols="6" class="col-sm-3 pa-2">
            <v-card class="d-flex flex-column align-center justify-center rounded-0 primary px-4 py-2" dark flat>
              <span class="caption">Shipping</span>
              <div class="font-weight-bold">
                {{ parseInt(tableSummary.shipping) | price }}
              </div>
            </v-card>
          </v-col>
          <v-col cols="6" class="col-sm-3 pa-2">
            <v-card class="d-flex flex-column align-center justify-center rounded-0 primary px-4 py-2" dark flat>
              <span class="caption">Total</span>
              <div class="font-weight-bold">
                {{ parseInt(tableSummary.total) | price }}
              </div>
            </v-card>
          </v-col>
        </v-row>
        <v-data-table
          dense
          :headers="headers"
          :items="table"
          item-key="id"
          :server-items-length="tableTotal"
          :page.sync="tablePage"
          :items-per-page.sync="tableLimit"
          :options.sync="options"
          :footer-props="{
            itemsPerPageOptions: [8, 50, 100]
          }"
          :loading="tableLoading"
          loading-text="Loading... Please wait"
        >
          <template v-slot:[`item.id`]="{ item }">
            <div class="caption">
              HLF/{{ (item.store || 0) }}/TRX/{{ item.id }}
            </div>
          </template>
          <template v-slot:[`item.schedule_type`]="{ item }">
            <div class="py-2">
              <div class="d-flex align-center fill-width justify-space-between">
                <span class="d-flex align-center">
                  <v-chip x-small class="text-capitalize mr-2">
                    {{ item.schedule_type }}
                  </v-chip>
                  {{ item.customer_name || 'None' }}
                </span>
                <v-btn v-if="item.customer_phone" :href="'https://api.whatsapp.com/send?phone=' + item.customer_phone + '&text=Hello...'" icon target="_blank" x-small color="green">
                  <v-icon>
                    mdi-whatsapp
                  </v-icon>
                </v-btn>
              </div>
              <div class="rounded-lg grey lighten-4 px-2 py-1 caption mt-1">
                <div class="font-weight-bold d-flex fill-width align-center justify-space-between">
                  {{ item.product_quantity }}x {{ item.product_name || '-' }} - {{ item.product_variant_name || '-' }}
                  <small v-if="parseInt(item.amount_additional)" class="d-flex align-center primary--text">
                    <v-icon x-small class="mr-1" color="primary">
                      mdi-silverware-fork-knife
                    </v-icon>
                    Termasuk Alat Makan
                  </small>
                </div>
                <div class="rounded-lg grey lighten-5 px-2 py-1 caption mt-1" style="line-height: 120%;">
                  <div class="d-flex fill-width align-center justify-space-between">
                    {{ item.shipping_name }}, {{ item.shipping_phone }}
                    <v-btn v-if="item.shipping_phone" :href="'https://api.whatsapp.com/send?phone=' + item.shipping_phone + '&text=Hello...'" target="_blank" x-small color="green" class="text-capitalize white--text rounded-pill" depressed>
                      <v-icon x-small color="white" class="mr-1">
                        mdi-whatsapp
                      </v-icon>
                      Whatsapp
                    </v-btn>
                  </div>
                  <span class="d-block fill-width">
                    <span class="d-block fill-width">
                      {{ item.shipping_address }}
                    </span>
                    {{ item.shipping_kecamatan }} - {{ item.shipping_city }}
                  </span>
                  <div class="d-flex align-center fill-width py-1">
                    <v-icon class="mr-1" x-small>
                      mdi-calendar-text-outline
                    </v-icon>
                    {{ item.notes }}
                  </div>
                </div>
              </div>
            </div>
          </template>
          <template v-slot:[`item.paid`]="{ item }">
            <div class="text-right caption-small">
              {{ $price(item.paid) }}
              <div v-if="item.payment_description" class="caption-small font-italic fill-width align-left orange--text">
                #{{ item.payment_description }}
              </div>
            </div>
          </template>
          <template v-slot:[`item.amount_total`]="{ item }">
            <div class="text-right caption-small">
              {{ $price(item.amount_total) }}
            </div>
          </template>
          <template v-slot:[`item.payment_type`]="{ item }">
            <div class="caption-small">
              {{ item.payment_type }}/{{ item.shipping_method + (item.shipping_method === 'courier' ? '-' + item.shipping_method_courier : '') }}
            </div>
          </template>
          <template v-slot:[`item.review`]="{ item }">
            <div v-if="parseInt(item.status) === 3" class="d-flex flex-column align-start fill-width caption-small">
              {{ ((parseInt(item.review) || 0)/5).toFixed(1) }}
              <div class="pt-1">
                {{ item.review_comment || '-' }}
              </div>
            </div>
            <div v-else class="d-flex align-center fill-width caption-small">
              -
            </div>
          </template>
          <template v-slot:[`item.schedule_date`]="{ item }">
            <div class="caption-small">
              {{ $localDT(item.schedule_date, 'datelocal') }}
            </div>
          </template>
          <template v-slot:[`item.created`]="{ item }">
            <div class="caption-small">
              {{ $localDT(item.created, 'datelocal') }}
            </div>
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <v-chip :color="parseInt(item.status) === 1 ? 'blue' : (parseInt(item.status) === 2 ? 'orange darken-1' : (parseInt(item.status) === 3 ? 'green darken-1' : 'grey darken-1'))" dark small class="my-1">
              {{ parseInt(item.status) === 1 ? 'Scheduled' : (parseInt(item.status) === 2 ? 'Completed' : (parseInt(item.status) === 3 ? 'Delivery' : (item.status + ':undefined'))) }}
            </v-chip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog
      v-model="dialogTrx.show"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar
          dark
          color="indigo"
          class="elevation-0 fill-width"
          style="position: fixed;top: 0px;left: 0px;z-index: 2;"
        >
          <v-btn
            icon
            dark
            @click="TRX_GET(tableSearch), dialogTrx.show = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title class="d-flex justify-space-between align-center fill-width">
            {{ dialogTrx.data ? parseInt(dialogTrx.data.id) ? ('View Order HLF/' + parseInt(dialogTrx.data.store || 0) + '/TRX/' + parseInt(dialogTrx.data.id)) : 'Create New Order' : 'Undefined' }}
            <v-btn v-if="dialogTrx.data ? (!TRXEDITED && parseInt(dialogTrx.data.id)) : false" depressed class="rounded-pill text-capitalize" light color="white" @click.prevent="dialogStruk.show = true">
              Print
            </v-btn>
          </v-toolbar-title>
          <v-spacer />
        </v-toolbar>
        <v-container v-if="dialogTrx.data" class="pt-16">
          <v-row no-gutters>
            <v-col v-if="GENERATED_ID" cols="12" class="mt-0 mt-sm-4 d-flex flex-column justify-center flex-sm-row justify-sm-space-between grey-sm lighten-4">
              <!-- <barcode :value="GENERATED_ID" :width="1.5" :height="50" class="barcode-class">
                ERROR
              </barcode> -->
              <div class="d-flex flex-column">
                <barcode :value="GENERATED_ID" :display-value="false" :width="2.3" :height="50" class="barcode-class">
                  ERROR
                </barcode>
                <div class="d-flex justify-center title font-weight-bold fill-width align-center">
                  HLF/{{ $store.state.storeActive + '/' + GENERATED_ID }}
                </div>
              </div>
              <div class="d-flex align-center">
                <v-chip :color="parseInt(dialogTrx.data.dinein) ? 'blue' : 'orange'" dark class="px-4 font-weight-bold">
                  {{ parseInt(dialogTrx.data.dinein) ? 'Dine In' : 'Delivery' }}
                </v-chip>
              </div>
              <template v-if="dialogTrx.data">
                <div v-if="parseInt(dialogTrx.data.id)" :class="[$TrxStatus(dialogTrx.data.status).color]" class="white--text d-flex align-center py-4 py-sm-1 mt-4 mt-sm-0 px-10">
                  <v-icon small class="mr-2" color="white">
                    {{ $TrxStatus(dialogTrx.data.status).icon }}
                  </v-icon>
                  {{ $TrxStatus(dialogTrx.data.status).name }}
                  <v-chip v-if="parseInt(dialogTrx.data.id)" small color="white" class="red--text ml-4 c-pointer rounded-0" @click.prevent="ShowUpdateTrxStatus()">
                    Update
                  </v-chip>
                </div>
              </template>
            </v-col>
            <v-col cols="12" class="col-sm-6 pa-4">
              <div class="d-flex align-center">
                <v-icon class="mr-2">
                  mdi-package-variant-closed
                </v-icon>
                Pesanan
              </div>

              <v-divider class="mt-4" />
              <div v-if="TRXEDITED" class="black pa-4">
                <v-autocomplete
                  v-model="items.selected"
                  :search-input.sync="items.query"
                  :items="items.result"
                  item-text="product_fullname"
                  outlined
                  dense
                  hide-details
                  return-object
                  clearable
                  dark
                  append-icon="mdi-barcode"
                  class="rounded-0"
                  label="Cari Produk : Nama Produk/SKU"
                />
              </div>
              <v-divider class="mb-8" />
              <div>
                <div v-for="(i, iI) in cart" :key="'item-' + iI" class="d-flex fill-width justify-space-between mb-4 py-4 pl-4 pr-2 grey lighten-4">
                  <div class="d-flex fill-width">
                    <v-img
                      :src="$getImage(i.galleries)"
                      aspect-ratio="1"
                      class="grey lighten-2"
                      style="width: 100px!important"
                    />
                    <div class="d-flex flex-column fill-width pl-4">
                      <div class="grey--text text-uppercase">
                        <v-icon class="mr-1">
                          mdi-barcode
                        </v-icon>
                        <span class="caption">
                          {{ i.sku }}
                        </span>
                      </div>
                      <!-- {{ i.priceActiveData || i.trx_price_option }} -->
                      <div class="font-weight-bold">
                        {{ i.product_fullname }}, x{{ i.quantity }}
                      </div>
                      <div v-if="parseInt(i.trx)">
                        <div v-if="parseInt(i.trx_price_active) < parseInt(i.trx_price)" class="d-flex fill-width justify-space-between align-center body-2">
                          <div>
                            <span class="d-inline-block red--text mr-2 body-2" style="text-decoration: line-through">
                              {{ $price(i.trx_price) }}
                            </span>
                            <span class="d-inline-block body-2">
                              {{ $price(i.trx_price_active) }}
                            </span>
                          </div>
                          <span>
                            {{ $ConvWeight(i.trx_weight * i.quantity, 1, true) }}
                          </span>
                        </div>
                        <div v-else class="d-flex fill-width justify-space-between align-center body-2 pr-2">
                          {{ $price(i.trx_price) }}
                          <span>
                            {{ $ConvWeight(i.trx_weight * i.quantity, 1, true) }}
                          </span>
                        </div>
                      </div>
                      <div v-else>
                        <div v-if="parseInt(i.priceActive) < parseInt(i.price)" class="d-flex fill-width justify-space-between align-center body-2">
                          <div>
                            <span class="d-inline-block red--text mr-2 body-2" style="text-decoration: line-through">
                              {{ $price(i.price) }}
                            </span>
                            <span class="d-inline-block body-2">
                              {{ $price(i.priceActive) }}
                            </span>
                          </div>
                          <span>
                            {{ $ConvWeight(i.trx_weight * i.quantity, 1, true) }}
                          </span>
                        </div>
                        <div v-else class="d-flex fill-width justify-space-between align-center body-2">
                          {{ $price(i.price) }}
                          <span>
                            {{ $ConvWeight(i.weight * i.quantity, 1, true) }}
                          </span>
                        </div>
                      </div>
                      <div class="d-flex fill-width justify-space-between py-1 body-2 font-weight-bold">
                        Catatan : {{ i.notes || '-' }}
                      </div>
                      <div v-if="false" class="d-flex fill-width justify-space-between py-1">
                        <div class="d-flex align-center">
                          <v-btn v-if="!parseInt(i.trx)" icon x-small class="rounded-0" @click.prevent="CART_QUANTITY('min', iI)">
                            <v-icon>
                              mdi-minus
                            </v-icon>
                          </v-btn>
                          <input
                            v-model.number="cart[iI].quantity"
                            :disabled="parseInt(i.trx) ? true : false"
                            type="text"
                            class="mx-1 d-block grey lighten-2 text-center caption"
                            style="max-width: 45px;outline: none;"
                            @keypress="$NumOnly"
                            @keyup="!parseInt(cart[iI].quantity) ? (cart[iI].quantity = 0) : true"
                          >
                          <v-btn v-if="!parseInt(i.trx)" icon x-small class="rounded-0" @click.prevent="CART_QUANTITY('plus', iI)">
                            <v-icon>
                              mdi-plus
                            </v-icon>
                          </v-btn>
                        </div>
                        <div :class="[parseInt(i.trx) ? 'pr-2' : '']" class="d-flex align-center font-weight-bold">
                          {{ parseInt(i.trx) ? $price(i.trx_price_sum) : $price(parseInt(i.priceActive) * parseInt(i.quantity)) }}
                        </div>
                      </div>
                      <template v-if="i.trx_price_option">
                        <div v-if="i.trx_price_option.type === 'gift' && i.trx_price_option.gift" class="d-flex align-center caption orange--text text--darken-1">
                          <v-icon small class="mr-1" color="orange darken-1">
                            mdi-wallet-giftcard
                          </v-icon>
                          Free Gift : {{ i.trx_price_option.gift }}
                        </div>
                      </template>
                    </div>
                  </div>
                  <div>
                    <v-btn v-if="!parseInt(i.trx)" icon color="red" small @click.prevent="cart.splice(iI, 1)">
                      <v-icon>
                        mdi-close
                      </v-icon>
                    </v-btn>
                  </div>
                </div>
                <v-divider class="my-4" />
                <div class="d-flex justify-end fill-width mb-4">
                  <div class="d-flex align-center orange white--text px-6 py-4">
                    <div class="d-flex align-center font-weight-black mr-4">
                      <v-icon medium class="mr-2" color="white">
                        mdi-cash-multiple
                      </v-icon>
                      <span class="text-sm-h6">SUBTOTAL :</span>
                    </div>
                    <div v-if="!TRXEDITED" class="text-h5 font-weight-black">
                      {{ $price(dialogTrx.data.amount_subtotal) }}
                      <!-- /<small class="font-weight: normal;">{{ $ConvWeight(dialogTrx.data.weight, 1, true) }}</small> -->
                    </div>
                    <div v-else class="text-h5 font-weight-black">
                      {{ $price($total(cart, customer.selected)) }}
                      <!-- /<small class="font-weight: normal;">{{ $ConvWeight($CART_PARSE(cart).weight, 1, true) }}</small> -->
                    </div>
                  </div>
                </div>
              </div>
              <div class="pt-4">
                <div class="d-flex align-center">
                  <v-icon class="mr-2">
                    mdi-note-text-outline
                  </v-icon>
                  Catatan Order
                </div>
                <v-divider class="my-4" />
                <div class="grey lighten-4 grey--text text--darken-2 pa-4">
                  {{ dialogTrx.data.notes || 'Tidak ada catatan!' }}
                </div>
              </div>
            </v-col>
            <v-col cols="12" class="col-sm-6 pa-4">
              <div class="d-flex align-center">
                <v-icon class="mr-2">
                  mdi-information-outline
                </v-icon>
                Detail Pesanan
              </div>

              <v-divider class="mt-4" />
              <div v-if="TRXEDITED" class="black pa-4">
                <!-- :item-text="(r) => (r.name + ' ' + (r.email ? '- ' + r.email : '') + ' ' + (r.phone ? '| ' + r.phone : ''))" -->
                <v-autocomplete
                  v-model="customer.selected"
                  :search-input.sync="customer.query"
                  :items="customer.result"
                  item-text="detailname"
                  outlined
                  dense
                  dark
                  hide-details
                  return-object
                  clearable
                  autocomplete="none"
                  prepend-icon="mdi-magnify"
                  append-outer-icon="mdi-plus"
                  class="rounded-0"
                  label="Cari Pelanggan : ID/Nama/Email/No HP"
                  @click:append-outer="ShowCustomer()"
                />
              </div>
              <v-divider class="mb-4" />
              <div v-if="customer.selected" class="d-flex fill-width flex-column flex-sm-row justify-sm-space-between pa-4 grey darken-1 mb-4">
                <div class="d-flex pb-4 pb-sm-0">
                  <div style="width: 100px!important">
                    <v-img
                      :src="customer.selected.img"
                      aspect-ratio="1"
                      class="grey lighten-2"
                    />
                  </div>
                  <div class="pl-4">
                    <div class="d-flex align-center text-uppercase">
                      <v-icon class="mr-1" color="white">
                        mdi-barcode
                      </v-icon>
                      <span class="body-2 white--text font-weight-bold">
                        HLF/CUSTOMER/{{ customer.selected.id }}
                      </span>
                    </div>
                    <div class="font-weight-bold mb-2 white--text">
                      {{ customer.selected.name }}
                    </div>
                    <div v-if="TRXEDITED" class="caption white px-2 py-1 text-center">
                      {{ parseInt(customer.selected.type) === 1 ? 'Special' : 'Default' }} Member
                    </div>
                    <div v-else class="d-flex fill-width">
                      <div class="caption white px-2 py-1 text-center mr-2">
                      {{ parseInt(dialogTrx.data.customer_type) === 1 ? 'Special' : 'Default' }} Member
                      </div>
                      <!-- <div class="caption">
                        <v-icon small class="mr-1">
                          mdi-information
                        </v-icon> Pada saat Transaksi
                      </div> -->
                    </div>
                  </div>
                </div>
                <div>
                  <div class="d-flex align-center caption white--text font-weight-bold">
                    <v-icon small class="mr-2" color="white">
                      mdi-cellphone
                    </v-icon>
                    {{ customer.selected.phone }}
                  </div>
                  <div class="d-flex align-center caption white--text font-weight-bold">
                    <v-icon small class="mr-2" color="white">
                      mdi-email-outline
                    </v-icon>
                    {{ customer.selected.email }}
                  </div>
                  <div class="d-flex align-center pt-1">
                    <v-btn
                      v-if="customer.selected.phone"
                      :href="'https://api.whatsapp.com/send?phone=' + customer.selected.phone + '&text=Hi%20' + customer.selected.name + '...'"
                      target="_blank"
                      x-small
                      color="green"
                      dark
                      depressed
                      class="mr-2 text-capitalize rounded-pill white--text"
                    >
                      <v-icon x-small class="mr-1">
                        mdi-whatsapp
                      </v-icon>
                      Whatsapp
                    </v-btn>
                    <v-btn
                      v-if="customer.selected.phone"
                      :href="'tel:' + customer.selected.phone"
                      target="_blank"
                      x-small
                      color="blue"
                      dark
                      depressed
                      class="text-capitalize rounded-pill white--text"
                    >
                      <v-icon x-small class="mr-1">
                        mdi-phone
                      </v-icon>
                      Call
                    </v-btn>
                  </div>
                  <div class="pt-2">
                    <v-chip color="primary lighten-4 black--text" small light>
                      {{ parseInt(customer.selected.pointActual || 0) }} Poin
                    </v-chip>
                  </div>
                </div>
              </div>

              <div v-if="!parseInt(dialogTrx.data.dinein)" class="d-flex align-center pt-4">
                <v-icon class="mr-2">
                  mdi-truck-delivery-outline
                </v-icon>
                Shipping
              </div>
              <v-divider class="my-4" />
              <div class="pl-0 pl-sm-7">
                <v-radio-group v-if="!parseInt(dialogTrx.data.dinein)" v-model="dialogTrx.data.shipping_method" :disabled="!TRXEDITED">
                  <!-- <v-radio value="none">
                    <template v-slot:label>
                      <div class="d-flex align-center fill-width">
                        <v-icon class="mr-2">
                          mdi-map-marker
                        </v-icon>
                        Ambil di <strong class="indigo--text ml-1">Tempat</strong>
                      </div>
                    </template>
                  </v-radio> -->
                  <!-- <v-radio v-if="customer.selected" value="driver">
                    <template v-slot:label>
                      <div class="d-flex align-center fill-width">
                        <v-icon class="mr-2">
                          mdi-motorbike
                        </v-icon>
                        Kirim pakai <strong class="indigo--text ml-1">Driver</strong>
                      </div>
                    </template>
                  </v-radio> -->
                  <v-radio v-if="customer.selected" value="courier">
                    <template v-slot:label>
                      <div class="d-flex align-center fill-width">
                        <v-icon class="mr-2">
                          mdi-truck-fast
                        </v-icon>
                        Kirim Ekspedisi
                        <select v-model="dialogTrx.data.shipping_method_courier" class="d-inline-block indigo darken-2 caption py-1 px-2 rounded-lg white--text font-weight-bold ml-1 c-pointer" style="outline: none;">
                          <option value="jne">
                            JNE
                          </option>
                          <option value="jnt">
                            JNT
                          </option>
                          <option value="sicepat">
                            SICEPAT
                          </option>
                          <option value="pos">
                            POS
                          </option>
                        </select>
                        <v-icon small class="ml-1" color="black">
                          mdi-chevron-down
                        </v-icon>
                      </div>
                    </template>
                  </v-radio>
                  <!-- <v-radio v-if="customer.selected" value="freight">
                    <template v-slot:label>
                      <div class="d-flex align-center fill-width">
                        <v-icon class="mr-2">
                          mdi-truck-fast
                        </v-icon>
                        Kirim via
                        <strong class="indigo--text ml-1">Kargo / Kapal</strong>
                      </div>
                    </template>
                  </v-radio> -->
                </v-radio-group>
                <div v-if="dialogTrx.data.shipping_method === 'freight' || (dialogTrx.data.shipping_driver_image && dialogTrx.data.shipping_method_courier === 'driver')" class="d-flex align-center grey lighten-2 rounded-lg pa-4 mb-4">
                  <div v-if="dialogTrx.data.shipping_driver_image" class="d-flex flex-column justify-center">
                    <a :href="dialogTrx.data.shipping_driver_image" target="_blank" class="d-flex align-center justify-center" style="height: 100px;width: 100px;">
                      <span v-if="loadingImageUpload" style="font-size:8px;">
                        uploading..
                      </span>
                      <img v-else :src="dialogTrx.data.shipping_driver_image" class="d-block" style="max-height: 100%;max-width:100%;">
                    </a>
                    <span v-if="dialogTrx.data.shipping_driver_image" class="orange white--text rounded-pill c-pointer text-center" style="font-size:8px;padding: 2px 10px;" @click.prevent="UploadImage()">
                      Change Image
                    </span>
                  </div>
                  <div
                    v-else
                    :class="[parseInt(dialogTrx.data.status) < 4 ? 'c-pointer' : '']"
                    class="d-flex align-center justify-center caption-small grey lighten-1 rounded-lg"
                    style="height: 100px;width: 100px;"
                    @click.prevent="loadingImageUpload ? '' : (parseInt(dialogTrx.data.status) < 4 ? UploadImage() : '')"
                  >
                    <span v-if="parseInt(dialogTrx.data.status) < 4">
                      {{ loadingImageUpload ? 'uploading..' : '+ Image' }}
                    </span>
                    <span v-else>
                      No Image
                    </span>
                  </div>
                  <div class="pl-4 d-flex align-end flex-column" style="width:calc(100% - 100px);">
                    <v-textarea
                      v-model="dialogTrx.data.shipping_driver_notes"
                      hide-details
                      outlined
                      block
                      dense
                      rows="4"
                      label="Shipping Notes"
                      class="body-2 d-flex fill-width"
                      style="line-height: 100%;"
                    />
                    <span v-if="dialogTrx.data.id" class="mt-4 blue white--text rounded-pill c-pointer text-center" style="font-size:8px;padding: 2px 10px;" @click.prevent="ProcessUpdateTrx(true)">
                      Update
                    </span>
                  </div>
                </div>
                <div v-if="customer.selected && dialogTrx.data.shipping_method !== 'none' && !parseInt(dialogTrx.data.dinein)">
                  <v-autocomplete
                    v-if="TRXEDITED"
                    v-model="customerAddressSelected"
                    :disabled="!TRXEDITED"
                    :items="customerAddress"
                    :item-text="function (r) {
                      return r.name + ' - ' + r.kecamatan + ', ' + r.city
                    }"
                    outlined
                    dense
                    hide-details
                    return-object
                    clearable
                    autocomplete="disabled"
                    prepend-icon="mdi-map-marker"
                    label="Select Address"
                  >
                    <template v-slot:append-outer>
                      <v-icon
                        @click="AddAddress(customerAddressSelected)"
                        v-text="customerAddressSelected ? 'mdi-pencil' : 'mdi-plus'"
                      />
                    </template>
                  </v-autocomplete>
                  <div v-if="TRXEDITED && customerAddressSelected" class="pl-8 pt-2 pb-4">
                    <div class="body-2 font-weight-bold">
                      {{ customerAddressSelected.name }} - {{ customerAddressSelected.phone }}
                    </div>
                    <div class="body-2">
                      {{ customerAddressSelected.address }}
                    </div>
                    <div class="body-2 d-flex align-center font-weight-bold grey--text text--darken-2">
                      <v-icon small class="mr-2">
                        mdi-city
                      </v-icon>
                      {{ customerAddressSelected.kecamatan }}, {{ customerAddressSelected.city }}
                    </div>
                  </div>
                  <div v-else-if="!TRXEDITED" class="pl-8 pt-4 pb-4 grey lighten-4">
                    <div class="body-2 font-weight-bold">
                      {{ dialogTrx.data.shipping_name }} - {{ dialogTrx.data.shipping_phone }}
                    </div>
                    <div class="body-2">
                      {{ dialogTrx.data.shipping_address }}
                    </div>
                    <div class="body-2 d-flex align-center font-weight-bold grey--text text--darken-2">
                      <v-icon small class="mr-2">
                        mdi-city
                      </v-icon>
                      {{ dialogTrx.data.shipping_city }}
                    </div>
                  </div>
                </div>
                <div v-if="customer.selected" class="d-flex flex-column justify-center">
                  <div class="d-lfex align-center">
                    <v-icon class="mr-2">
                      mdi-adjust
                    </v-icon>
                    Gunakan Poin (max {{ parseInt(customer.selected.pointActual || 0) }} Poin) => 1 Poin = Rp1,000
                  </div>
                  <div class="d-flex fill-width align-center pt-4">
                    <v-text-field
                      v-model.number="newPoinUpdate"
                      :disabled="parseInt(dialogTrx.data.status) > 0 || loadingProcess"
                      :suffix="'Poin => ' + $price(PoinUsed)"
                      :label="parseInt(dialogTrx.data.point_used) ? 'Poin digunakan' : 'Masukkan Jumlah Poin'"
                      outlined
                      dense
                      hide-details
                      class="mb-4 rounded-pill mr-2"
                      @keypress="$NumOnly"
                      @keyup="dialogTrx.data.point_used_price_100 = 1000, parseInt(newPoinUpdate) ? (parseInt(newPoinUpdate) > parseInt(customer.selected.pointActual || 0) ? newPoinUpdate = parseInt(customer.selected.pointActual || 0) : '') : newPoinUpdate = 0"
                    />
                    <v-btn
                      :disabled="parseInt(dialogTrx.data.status) > 0 || loadingProcess"
                      depressed
                      class="rounded-pill mb-4 text-capitalize font-weight-bold"
                      color="primary"
                      dark
                      @click.prevent="ProcessUpdateTrxPoin()"
                    >
                      Update
                    </v-btn>
                  </div>
                </div>
                <v-divider v-if="customer.selected" class="mb-4 mt-2" />
                <div v-if="customer.selected && dialogTrx.data.shipping_method === 'courier' && customerAddressSelected && !parseInt(dialogTrx.data.dinein)" class="pt-2">
                  <v-autocomplete
                    v-model="customerShippingSelected"
                    :items="customerShippingList"
                    :item-text="function (r) {
                      return (dialogTrx.data.shipping_method_courier).toUpperCase() + ' ' + r.service_name + ' ' + r.etd_value + (dialogTrx.data.shipping_method_courier === 'jne' ? 'day(s)' : '') + ' : ' + $price(r.cost_value) + '/kg'
                    }"
                    outlined
                    dense
                    hide-details
                    return-object
                    clearable
                    autocomplete="disabled"
                    prepend-icon="mdi-truck-fast"
                    label="Shipping Method"
                  />
                </div>
                <div v-if="customer.selected && dialogTrx.data.shipping_method === 'courier' && !parseInt(dialogTrx.data.dinein)" class="d-flex justify-end fill-width">
                  <div v-if="!TRXEDITED" class="text-h7 font-weight-bold grey--text text--darken-2 pt-6">
                    Shipping Cost (<small class="font-weight: normal;">{{ $ConvWeight(dialogTrx.data.weight, 1, true) }}</small>) : +{{ $price(dialogTrx.data.amount_shipping) }}
                  </div>
                  <div v-else-if="customerAddressSelected" class="text-h7 pt-6">
                    Shipping Cost (<small class="font-weight: normal;">{{ $ConvWeight($CART_PARSE(cart).weight, 1, true) }}</small>) : +{{ $price(COUNT_ONGKIR) }}
                  </div>
                </div>
                <div v-if="!TRXEDITED" class="d-flex justify-end fill-width">
                  <div class="text-h7 font-weight-bold grey--text text--darken-2 pt-0">
                    Donation : +{{ $price(dialogTrx.data.amount_donation) }}
                  </div>
                </div>
                <div v-if="!TRXEDITED && dialogTrx.data.voucher_code && parseInt(dialogTrx.data.voucher_value)" class="d-flex justify-end fill-width">
                  <div class="text-h7 font-weight-bold grey--text text--darken-2 pt-0">
                    Voucher {{ dialogTrx.data.voucher_code }} : <span class="red--text">-{{ $price(dialogTrx.data.voucher_value) }}</span>
                  </div>
                </div>
                <div v-if="!TRXEDITED && parseInt(dialogTrx.data.point_used)" class="d-flex justify-end fill-width">
                  <div class="text-h7 font-weight-bold grey--text text--darken-2 pt-0">
                    Using {{ dialogTrx.data.point_used }} Poin : <span class="red--text">-{{ $price(PoinUsed) }}</span>
                  </div>
                </div>

                <div class="d-flex justify-end fill-width mb-4 pt-6 pb-4">
                  <div class="d-flex align-center orange darken-2 white--text px-6 py-4">
                    <div class="d-flex align-center font-weight-black mr-4">
                      <v-icon medium class="mr-2" color="white">
                        mdi-cash-multiple
                      </v-icon>
                      <span class="text-sm-h6">TOTAL :</span>
                    </div>
                    <div class="text-h5 font-weight-black">
                      {{ !TRXEDITED ? $price(parseInt(dialogTrx.data.amount_total)) : $price($total(cart, customer.selected) + COUNT_ONGKIR) }}
                    </div>
                  </div>
                </div>
                <v-divider v-if="parseInt(dialogTrx.data.id)" class="mt-0 mb-6" />
                <div v-if="parseInt(dialogTrx.data.id)" class="d-flex flex-column align-end fill-width mb-4 pt-0 pb-4">
                  <div class="d-flex align-center purple darken-2 white--text px-6 py-2 mb-2">
                    <div class="d-flex align-center font-weight-black mr-4">
                      <v-icon medium class="mr-2" color="white">
                        mdi-cash-check
                      </v-icon>
                      <span class="text-sm-h8">DIBAYAR :</span>
                    </div>
                    <div class="text-h8 font-weight-black">
                      {{ $price(dialogTrx.data.paid) }}
                    </div>
                  </div>
                  <div class="d-flex align-center indigo darken-2 white--text px-6 py-2">
                    <div class="d-flex align-center font-weight-black mr-4">
                      <v-icon medium class="mr-2" color="white">
                        mdi-cash-fast
                      </v-icon>
                      <span class="text-sm-h8">SISA :</span>
                    </div>
                    <div class="text-h8 font-weight-black">
                      {{ $price(parseInt(dialogTrx.data.amount_total) - parseInt(dialogTrx.data.paid)) }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="d-flex align-center pt-4">
                <v-icon class="mr-2">
                  mdi-cash
                </v-icon>
                Payment Default
              </div>
              <v-divider class="my-4" />
              <div v-if="$total(cart, customer.selected) > 0" class="px-7 grey lighten-4 pt-1 pb-0">
                <v-radio-group v-model="dialogTrx.data.payment_type" :disabled="!TRXEDITED">
                  <v-radio v-for="(p, iP) in $store.state.payment.filter(r => (r.customerRequired ? (customer.selected ? true : false) : true))" :key="'payment-' + iP" :value="p.id">
                    <template v-slot:label>
                      <div class="d-flex align-center fill-width">
                        <v-icon class="mr-2">
                          mdi-cash
                        </v-icon>
                        Bayar <strong class="success--text ml-1">{{ p.name }}</strong>
                      </div>
                    </template>
                  </v-radio>
                  <!-- <v-radio v-if="!parseInt(dialogTrx.data.cash)" value="hutang">
                    <template v-slot:label>
                      <div class="d-flex align-center fill-width">
                        <v-icon class="mr-2">
                          mdi-cash
                        </v-icon>
                        Bayar <strong class="success--text ml-1">Nanti (Hutang)</strong>
                      </div>
                    </template>
                  </v-radio> -->
                  <!-- <v-radio v-if="dialogTrx.data.shipping_method === 'driver'" value="cod">
                    <template v-slot:label>
                      <div class="d-flex align-center fill-width">
                        <v-icon class="mr-2">
                          mdi-cube-send
                        </v-icon>
                        Bayar COD <strong class="primary--text ml-1">Cash On Delivery</strong>
                      </div>
                    </template>
                  </v-radio> -->
                </v-radio-group>
                <!-- <div v-if="$total(cart, customer.selected)" class="payment-process"> -->
                <div v-if="false" class="payment-process">
                  <!-- CASH PAYMENT -->
                  <div>
                    <v-text-field
                      v-model.number="dialogTrx.data.cash"
                      :disabled="!TRXEDITED"
                      :label="(dialogTrx.data.payment_type === 'cash' ? 'Uang Tunai' : 'Jumlah Pembayaran') + ' (0 => Hutang)'"
                      outlined
                      dense
                      hide-details
                      prefix="Rp"
                      class="mb-4"
                      append-icon="mdi-account-cash"
                      @keypress="$NumOnly"
                      @keyup="TRXEDITED ? dialogTrx.data.cash_refund = (dialogTrx.data.cash - ($total(cart, customer.selected) + COUNT_ONGKIR)) : null"
                    />
                    <v-text-field
                      v-model.number="dialogTrx.data.cash_refund"
                      :disabled="!TRXEDITED"
                      readonly
                      outlined
                      dense
                      hide-details
                      prefix="Rp"
                      :label="parseInt(dialogTrx.data.cash) ? ((dialogTrx.data.cash_refund < 0 ? 'Kekurangan Pembayaran' : 'Sisa Pembayaran')) : 'Hutang'"
                      append-icon="mdi-cash-refund"
                      class="mb-4 grey lighten-3"
                    />
                    <v-divider class="my-4" />
                  </div>
                  <!-- <v-text-field
                    v-model="dialogTrx.data.payment_description"
                    :disabled="!TRXEDITED"
                    outlined
                    dense
                    hide-details
                    prepend-inner-icon="mdi-comment-outline"
                    label="Payment Notes"
                    class="mb-4"
                  /> -->
                </div>
              </div>
              <div v-if="dialogTrx.data.payment_description" class="pt-4">
                <a v-if="dialogTrx.data.payment_description.match(/http/gi)" :href="dialogTrx.data.payment_description" target="_blank" class="d-block">
                  <div class="pb-2">
                    Payment Receipt
                  </div>
                  <img class="d-block" style="width:80px;" :src="dialogTrx.data.payment_description">
                </a>
              </div>
              <div v-else-if="dialogTrx.data.payment_type === 'bank_transfer'" class="pt-4">
                <div class="pb-2 black--text">
                  Payment Receipt
                  <div class="caption">
                    Notes : {{ dialogTrx.data.payment_notes }}
                  </div>
                </div>
                <span class="red--text caption">No payment receipt</span>
              </div>
              <!-- <v-textarea
                v-model="dialogTrx.data.notes"
                :disabled="!TRXEDITED"
                outlined
                dense
                rows="2"
                hide-details
                prepend-inner-icon="mdi-information"
                label="Order Notes"
                class="my-4"
              /> -->

              <v-divider v-if="$total(cart, customer.selected) > 0" class="my-4" />
              <div v-if="$total(cart, customer.selected) > 0" class="d-flex align-center fill-width justify-space-between">
                <div class="d-flex align-center">
                  <v-icon class="mr-2">
                    mdi-cash-register
                  </v-icon>
                  {{ dialogTrx.data.id ? 'Payments' : 'Payment'}}
                </div>
                <v-btn v-if="parseInt(dialogTrx.data.id)" small dark depressed color="indigo text-capitalize rounded-pill" @click.prevent="TRX_PAYMENT_SHOW()">
                  <v-icon class="mr-1" small>
                    mdi-plus
                  </v-icon>
                  Add Payment
                </v-btn>
              </div>
              <v-divider v-if="$total(cart, customer.selected) > 0" class="mt-4" />
              <v-row v-if="parseInt(dialogTrx.data.id)" no-gutters class="py-2">
                <v-col v-if="!trxPayments.length" cols="12" class="d-flex align-center justify-center pa-4 caption-small grey--text">
                  No payment data
                </v-col>
                <v-col v-for="(payment, iPayment) in trxPayments" :key="'payment-history-' + iPayment" cols="12" class="d-flex align-center justify-space-between pa-2 lime lighten-4 my-1">
                  <div class="d-flex align-center">
                    <v-chip class="mr-2 font-weight-bold" small>
                      #{{ iPayment + 1 }}
                    </v-chip>
                    <div class="d-flex flex-column">
                      <div class="caption-small grey--text text--darken-2">
                        {{ $localDT(payment.created, 'datelocal') }}
                      </div>
                      <div class="text-truncate caption" style="max-width:200px;">
                        <strong>{{ $price(payment.amount) }}</strong> : {{ XenditPaymentParse(payment).status ? XenditPaymentParse(payment).notes : payment.notes }}
                      </div>
                    </div>
                  </div>
                  <div class="d-flex align-center">
                    <a v-if="XenditPaymentParse(payment).status" :href="XenditPaymentParse(payment).invoice_url" target="_blank" class="mr-2 text-capitalize pink rounded-pill white--text caption-small px-2">
                      #INV
                    </a>
                    <v-chip x-small color="orange" dark class="mr-1 text-capitalize">
                      {{ payment.payment_type }}
                    </v-chip> /
                    <v-chip x-small :color="parseInt(payment.status) ? 'green darken-2' : 'red darken-2'" dark class="ml-1" @click.prevent="TRX_PAYMENT_SHOW(payment)">
                      {{ parseInt(payment.status) ? 'Paid' : 'Unpaid' }}
                    </v-chip>
                  </div>
                </v-col>
              </v-row>
              <!-- <div v-if="dialogTrx.data.xendit_inv" class="px-7 grey lighten-4 pt-2 pb-2 d-flex align-center">
                Xendit Payment
                <v-btn :href="dialogTrx.data.xendit_inv" depressed small target="_blank" color="blue" class="white--text text-capitalize rounded-pill ml-2">
                  Open Invoice
                </v-btn>
              </div> -->
              <div v-if="TRXEDITED" class="grey lighten-3 pa-4">
                <v-btn :disabled="!$total(cart, customer.selected) || loadingProcess" depressed color="blue" large dark block class="rounded-0 text-capitalize" @click.prevent="TRX_PROCESS()">
                  <v-icon class="mr-2">
                    mdi-content-save-outline
                  </v-icon>
                  PROSES TRANSAKSI
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="newCustomer.show"
      persistent
      scrollable
      max-width="290"
    >
      <v-card>
        <v-card-title class="body-1 justify-center">
          Create New Customer
        </v-card-title>
        <v-divider />
        <v-card-text class="pt-4" style="max-height: 300px;">
          <v-text-field
            v-model="newCustomer.data.name"
            outlined
            dense
            hide-details
            label="Name"
            class="mb-4"
          />
          <v-text-field
            v-model="newCustomer.data.email"
            outlined
            dense
            hide-details
            label="Email"
            class="mb-4 rounded-0"
          />
          <v-text-field
            v-model="newCustomer.data.phone"
            outlined
            dense
            hide-details
            label="Phone"
            class="mb-4 rounded-0"
            @keypress="$NumOnly"
          />
          <v-select
            v-model.number="newCustomer.data.type"
            :items="[{ name: 'Default Member', value: 0 }, { name: 'Special Member', value: 1 }]"
            item-text="name"
            item-value="value"
            outlined
            dense
            hide-details
            class="rounded-0"
            label="Customer Type"
          />
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="red darken-1"
            text
            @click="newCustomer.show = false"
          >
            Cancel
          </v-btn>
          <v-btn
            :disabled="loadingProcess"
            color="green darken-1"
            text
            @click="ProcessCustomer()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogUpdateTrx.show"
      persistent
      scrollable
      max-width="290"
    >
      <v-card v-if="dialogTrx.data">
        <v-card-title class="body-1 justify-center">
          Update Status {{ ('#HLF/' + parseInt(dialogTrx.data.store || 0) + '/TRX/' + parseInt(dialogTrx.data.id)) }}
        </v-card-title>
        <v-divider />
        <v-card-text class="py-8" style="max-height: 300px;">
          <v-select
            v-model.number="dialogUpdateTrx.status"
            :items="[{ name: 'Menunggu Pembayaran', value: 0 }, { name: 'Diproses', value: 1 }, { name: 'Siap Dikirim', value: 2 }, { name: 'Dikirim', value: 3 }, { name: 'Selesai', value: 4 }, { name: 'Canceled', value: 99 }]"
            item-text="name"
            item-value="value"
            outlined
            dense
            hide-details
            class="rounded-0 mb-4"
            label="Status Pesanan"
          />
          <!-- <v-select
            v-if="dialogTrx.data.shipping_method == 'driver' && parseInt(dialogUpdateTrx.status) > 1 && parseInt(dialogUpdateTrx.status) < 4"
            v-model.number="dialogUpdateTrx.shipping_driver"
            :items="drivers"
            item-text="name"
            item-value="id"
            outlined
            dense
            hide-details
            class="rounded-0"
            label="Select Driver"
          /> -->
          <v-text-field
            v-if="dialogTrx.data.shipping_method == 'courier' && parseInt(dialogTrx.data.id) && parseInt(dialogUpdateTrx.status) >= 2 && parseInt(dialogUpdateTrx.status) <= 4"
            v-model="dialogUpdateTrx.shipping_awb"
            outlined
            dense
            hide-details
            label="Shipping AWB"
            class="rounded-0"
          />
          <!-- <v-textarea
            v-if="parseInt(dialogTrx.data.id)"
            v-model="dialogUpdateTrx.shipping_awb"
            rows="2"
            outlined
            dense
            hide-details
            label="Tambahkan Catatan"
            class="mb-4"
          /> -->
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-btn
            color="red darken-1"
            text
            class="text-capitalize"
            @click="dialogUpdateTrx.show = false"
          >
            Cancel
          </v-btn>
          <v-spacer />
          <v-btn
            :disabled="loadingProcess"
            color="blue darken-1"
            depressed
            small
            dark
            class="text-capitalize rounded-0"
            @click="ProcessUpdateTrx()"
          >
            Update Status
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogStruk.show"
      width="375"
      class="xxx"
      content-class="dialog-printed black--text"
    >
      <v-card v-if="dialogStruk.show">
        <v-card-title class="d-flex justify-center">
          <!-- <barcode :value="GENERATED_ID" :width="1.2" :height="45" class="barcode-class">
            ERROR
          </barcode> -->
          <barcode :value="GENERATED_ID" :display-value="false" :width="2.3" :height="45" class="barcode-class">
             ERROR
          </barcode>
          <div class="d-flex fill-width subtitle-2 justify-center font-weight-bold">
            HLF/{{ $store.state.storeActive + '/' + GENERATED_ID }}
          </div>
        </v-card-title>
        <v-divider />
        <v-card-text class="py-3 caption black--text" style="letter-spacing:0px!important;">
          <div v-if="customer.selected" class="black--text">
            <div class="d-flex fill-width justify-center justify-space-between">
              <div class="d-flex align-center text-uppercase">
                <span class="font-weight-bold">
                  # HLF/CUST/{{ customer.selected.id }}
                </span>
              </div>
              <div class="font-weight-bold">
                {{ customer.selected.name }} ({{ parseInt(dialogTrx.data.customer_type) === 1 ? 'Special' : 'Default' }})
              </div>
            </div>
            <div class="d-flex justify-space-between fill-width">
              <div class="d-flex align-center">
                <v-icon x-small class="mr-1">
                  mdi-cellphone
                </v-icon>
                {{ customer.selected.phone }}
              </div>
              <div class="d-flex align-center">
                <v-icon x-small class="mr-1">
                  mdi-email-outline
                </v-icon>
                {{ customer.selected.email }}
              </div>
            </div>
            <div v-if="dialogTrx.data.shipping_method !== 'none' && dialogTrx.data.shipping_city" class="pt-3" style="line-height: 140%;">
              SHIP TO :
            </div>
            <v-divider v-if="dialogTrx.data.shipping_method !== 'none' && dialogTrx.data.shipping_city" class="my-1" />
            <div v-if="dialogTrx.data.shipping_method !== 'none' && dialogTrx.data.shipping_city" class="black--text pa-2" style="line-height: 140%;">
              <div>
                {{ dialogTrx.data.shipping_name }} - {{ dialogTrx.data.shipping_phone }}
              </div>
              <div class="">
                {{ dialogTrx.data.shipping_address }}
              </div>
              <div class="d-flex align-center justify-end black--text">
                <v-icon x-small class="mr-1">
                  mdi-city
                </v-icon>
                {{ dialogTrx.data.shipping_city }}
              </div>
            </div>
          </div>
          <v-divider class="mb-1" />
          Order Items :
          <div v-for="(i, iI) in cart" :key="'item-struk-' + iI" class="d-flex fill-width black--text">
            <div class="d-flex fill-width">
              <div class="d-flex justify-space-between fill-width">
                <div class="black--text">
                  {{ i.name }} (x{{ i.quantity }})
                  <!-- <span>
                    {{ $ConvWeight(i.trx_weight * i.quantity, 1, true) }}
                  </span> -->
                </div>
                <div class="d-flex black--text">
                  <div class="d-flex fill-width px-2 grey--text text--darken-2">
                    @{{ $price(i.trx_price_active) }}
                  </div>
                  <div class="d-flex fill-width justify-space-between align-center">
                    {{ parseInt(i.trx) ? $price(i.trx_price_sum) : $price(parseInt(i.priceActive) * parseInt(i.quantity)) }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <v-divider class="my-1" />
          Notes :
          <div class="black--text">
            {{ dialogTrx.data.notes || '-' }}
          </div>
          <v-divider class="mb-2 mt-1" />
          <div class="align-right black--text">
            <div class="d-flex justify-space-between align-center fill-width">
              <div>
                Subtotal
              </div>
              <div>
                {{ $price(dialogTrx.data.amount_subtotal) }}
              </div>
            </div>
            <div class="d-flex justify-space-between align-center fill-width">
              <div>
                Shipping Cost <span class="font-weight: normal;">{{ $ConvWeight(dialogTrx.data.weight, 1, true) }}</span>
              </div>
              <div>
                {{ $price(dialogTrx.data.amount_shipping) }}
              </div>
            </div>
            <div v-if="parseInt(dialogTrx.data.amount_donation)" class="d-flex justify-space-between align-center fill-width">
              <div>
                Donation
              </div>
              <div>
                {{ $price(dialogTrx.data.amount_donation) }}
              </div>
            </div>
            <div v-if="dialogTrx.data.voucher_code && parseInt(dialogTrx.data.voucher_value)" class="d-flex justify-space-between align-center fill-width">
              <div>
                Voucher <span class="font-weight: normal;">{{ dialogTrx.data.voucher_code }}</span>
              </div>
              <div class="red--text">
                -{{ $price(dialogTrx.data.voucher_value) }}
              </div>
            </div>
            <v-divider class="my-2" />
            <div class="d-flex justify-space-between align-center fill-width">
              <div>
                TOTAL
              </div>
              <div class="font-weight-bold">
                {{ $price(dialogTrx.data.amount_total) }}
              </div>
            </div>
          </div>
        </v-card-text>
        <v-divider class="no-print" />
        <v-card-actions class="no-print">
          <v-btn
            color="red"
            text
            small
            class="text-capitalize"
            @click="dialogStruk.show = false"
          >
            Close
          </v-btn>
          <v-spacer />
          <v-btn
            color="primary"
            text
            dark
            small
            class="text-capitalize"
            @click="PrintStruk()"
          >
            <v-icon small color="white" class="mr-1">
              mdi-print
            </v-icon>
            Print
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogAddress.show"
      persistent
      scrollable
      max-width="345"
    >
      <v-card v-if="dialogAddress.data">
        <v-card-title class="body-1 justify-center">
          {{ dialogAddress.data.id ? 'Update Address' : 'Create Address' }}
        </v-card-title>
        <v-divider />
        <v-card-text class="pt-4" style="max-height: 75vh;">
          <v-text-field
            v-model="dialogAddress.data.name"
            outlined
            dense
            hide-details
            label="Nama Penerima"
            class="mb-4"
          />
          <v-text-field
            v-model="dialogAddress.data.phone"
            outlined
            dense
            hide-details
            label="No HP"
            class="mb-4"
            @keypress="$NumberOnly"
          />
          <v-textarea
            v-model="dialogAddress.data.address"
            outlined
            dense
            hide-details
            label="Alamat Lengkap"
            class="mb-4"
          />
          <v-autocomplete
            v-model.number="dialogAddress.data.kecamatan_id"
            :name="Math.random()"
            :search-input.sync="areaquery"
            :items="areas"
            item-value="kecamatan_id"
            item-text="area_name"
            outlined
            dense
            hide-details
            label="Kecamatan, Kota, Provinsi"
            class="mb-4"
            autocomplete="disabled"
            @change="areaSync()"
          />
          <!-- <v-select
            v-model.number="newCustomer.data.type"
            :items="[{ name: 'Default Member', value: 0 }, { name: 'Special Member', value: 1 }]"
            item-text="name"
            item-value="value"
            outlined
            dense
            hide-details
            class="rounded-0"
            label="Customer Type"
          /> -->
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-btn
            color="orange darken-1"
            small
            dark
            text
            class="text-capitalize"
            @click="dialogAddress.show = false"
          >
            Cancel
          </v-btn>
          <v-btn
            v-if="dialogAddress.data.id"
            color="red darken-1"
            small
            dark
            text
            class="text-capitalize caption ml-2"
            @click="AddressDelete(dialogAddress.data.id)"
          >
            Delete Address
          </v-btn>
          <v-spacer />
          <v-btn
            :disabled="loadingProcess"
            depressed
            small
            dark
            color="rounded-lg indigo darken-2"
            class="text-capitalize"
            @click="ProcessAddress()"
          >
            {{ dialogAddress.data.id ? 'Update' : 'Save' }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogPayment.show"
      persistent
      scrollable
      max-width="290"
    >
      <v-card v-if="dialogPayment.data">
        <v-card-title class="body-1 justify-center">
          {{ parseInt(dialogPayment.data.id) ? 'Update Payment' : 'Create Payment' }}
        </v-card-title>
        <v-divider />
        <v-card-text class="pt-4" style="max-height: 300px;">
          <v-select
            v-model="dialogPayment.data.payment_type"
            :disabled="dialogPayment.data.payment_type === 'online' && parseInt(dialogPayment.data.id) ? true : false"
            :items="$store.state.payment.filter(r => (
              (dialogPayment.data.payment_type !== 'online' && r.id === 'online' && parseInt(dialogPayment.data.id))
                ? false
                : r.customerRequired
                  ? (customer.selected
                      ? true
                      : false
                    )
                  : true
            ))"
            item-text="name"
            item-value="id"
            outlined
            dense
            hide-details
            class="mb-4"
            label="Payment Type"
          />
          <v-text-field
            v-model.number="dialogPayment.data.amount"
            :disabled="dialogPayment.data.payment_type === 'online' && parseInt(dialogPayment.data.id) ? true : false"
            outlined
            dense
            hide-details
            label="Amount"
            class="mb-4"
            @keypress="$NumberOnly"
          />
          <v-textarea
            v-if="parseInt(dialogPayment.data.id) && dialogPayment.data.payment_type === 'online'"
            :value="XenditPaymentParse(dialogPayment.data).notes"
            :disabled="true"
            outlined
            dense
            rows="2"
            hide-details
            label="Notes"
            class="mb-4"
          />
          <v-textarea
            v-else
            v-model="dialogPayment.data.notes"
            outlined
            dense
            rows="2"
            hide-details
            label="Notes"
            class="mb-4"
          />
          <v-select
            v-if="!(dialogPayment.data.payment_type === 'online' && !parseInt(dialogPayment.data.id))"
            v-model.number="dialogPayment.data.status"
            :disabled="dialogPayment.data.payment_type === 'online'"
            :items="[{ name: 'Unpaid', value: 0 }, { name: 'Paid', value: 1 }]"
            item-text="name"
            item-value="value"
            outlined
            dense
            hide-details
            class="mb-4"
            label="Payment Status"
          />
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-btn
            color="orange darken-1"
            small
            dark
            text
            class="text-capitalize"
            @click="dialogPayment.show = false"
          >
            Close
          </v-btn>
          <v-btn
            v-if="parseInt(dialogPayment.data.id) && dialogPayment.data.payment_type !== 'online'"
            color="red darken-1"
            small
            dark
            text
            class="text-capitalize caption ml-2"
            @click="TRX_PAYMENT_DELETE(dialogPayment.data.id)"
          >
            Delete
          </v-btn>
          <v-spacer />
          <v-btn
            v-if="parseInt(dialogPayment.data.id) && dialogPayment.data.payment_type === 'online' ? false : true"
            :disabled="loadingProcess"
            depressed
            small
            dark
            color="rounded-lg indigo darken-2"
            class="text-capitalize"
            @click="TRX_PAYMENT_PROCESS()"
          >
            {{ parseInt(dialogPayment.data.id) ? 'Update' : 'Save' }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import VueBarcode from 'vue-barcode'
import { mapState } from 'vuex'
let searchInterval = null
let searchAreaTimeout = null
const newTrx = {
  id: 0,
  store: 0,
  platform: 0, // 0: offline store // 1: website
  customer: 0,
  cashier: 0,

  payment_type: 'bank_transfer', // online (xendit / payment gateway), bank_transfer, cod (driver), cash, card_debit, card_credit
  payment_description: '', // payment information
  payment_notes: '', // payment notes
  payment_status: 0, // 0: unpaid, 1: paid lunas, 2: paid hutang

  cash: 0, // cash uang tunai
  cash_refund: 0, // cash kembalian

  xendit_id: '', // xendit auto
  xendit_inv: '', // xendit auto
  xendit_ext: '', // xendit ext id (trx-trxid)
  weight: 0, // grams

  amount_subtotal: 0,
  amount_tax: 0,
  amount_shipping: 0,
  amount_total: 0,

  shipping_method: 'none', // none, driver or courier(driver, jne jnt, etc)
  shipping_method_courier: 'jne',
  shipping_method_type: '', // yes 1-2 days, etc
  shipping_awb: '', // yes 1-2 days, etc

  shipping_driver: 0,
  shipping_driver_lat: '',
  shipping_driver_lng: '',
  shipping_driver_status: 0, // 0: pickup, 1: shipping process, 2: complete, 99: pending, 100: cancel
  shipping_driver_notes: '',

  shipping_name: '',
  shipping_phone: '',
  shipping_address: '',
  shipping_postalcode: '',
  shipping_city: '',
  shipping_coordinate_lat: '',
  shipping_coordinate_lng: '',

  voucher: '',
  notes: '',
  created: '',
  status: 0 // 0: PENDING, 1: PACKAGING, 2: READY TO SHIP, 3: SHIPPED, 4: DELIVERED/COMPLETE, 99: Canceled/Expired, 100: Deleted
}
export default {
  name: 'Transaction',
  components: {
    barcode: VueBarcode
  },
  data: () => ({
    dialogStruk: {
      show: false
    },
    headers: [
      {
        text: 'INV ID',
        value: 'id'
      },
      { text: 'Schedule', value: 'schedule_date' },
      { text: 'Order Info', value: 'schedule_type' },
      { text: 'Total Amount', value: 'amount_total', align: 'right' },
      { text: 'Review', value: 'review', align: 'right' },
      // { text: 'Created', value: 'created' },
      { text: 'Status', value: 'status', align: 'right' }
      // { text: 'Opsi', value: 'option', sortable: false }
    ],
    dates: [],
    menu: false,
    tableSearch: '',
    tableLoading: false,
    tableAchievement: 3,
    table: [],
    tableTotal: 0,
    tablePage: 1,
    tableLimit: 50,
    tableSearchStatus: null,
    tableSearchPayment: '',
    tableSearchScheduleType: '',
    options: {},
    dialogTrx: {
      show: false,
      data: null
    },
    cart: [],
    items: {
      query: '',
      result: [],
      selected: null
    },
    customer: {
      query: '',
      result: [],
      selected: null
    },
    customerAddress: [],
    customerAddressSelected: null,
    customerShippingList: [],
    customerShippingSelected: null,
    newCustomer: {
      show: false,
      data: {
        id: null,
        name: '',
        email: '',
        phone: '',
        type: 0
      }
    },
    dialogUpdateTrx: {
      show: false,
      shipping_awb: '',
      shipping_driver: 0,
      status: 0
    },
    drivers: [],
    tableSummary: {
      quantity: 0,
      total_summary_normal: 0,
      total_summary: 0,
      tota: 0,
      total_summary_hpp: 0
    },
    dialogAddress: {
      show: false,
      data: null
    },
    areaquery: '',
    areas: [],
    trxPayments: [],
    dialogPayment: {
      show: false,
      data: null
    },
    loadingProcess: false,
    loadingImageUpload: false,
    newPoinUpdate: 0
  }),
  computed: {
    ...mapState({
      storeActive: state => state.storeActive
    }),
    PoinUsed () {
      if (parseInt(this.newPoinUpdate)) {
        return parseInt(this.newPoinUpdate) * parseInt(this.dialogTrx.data.point_used_price_100 || 0)
      }
      if (this.dialogTrx.show && parseInt(this.dialogTrx.data.point_used) > 0) {
        return parseInt(this.dialogTrx.data.point_used) * parseInt(this.dialogTrx.data.point_used_price_100 || 0)
      }
      return 0
    },
    TRXEDITED () {
      if (this.dialogTrx.show) {
        if (this.dialogTrx.data) {
          if (this.dialogTrx.data.id) {
            return false
          }
        }
      }
      return true
    },
    COUNT_ONGKIR () {
      const s = (this.customerShippingSelected ? parseInt(this.customerShippingSelected.cost_value) || 0 : 0)
      const cart = this.$CART_PARSE(this.cart)
      const weightCost = this.$SHIPPING_COST(cart.weight, s)
      if (this.customerShippingSelected) {
        return weightCost
      }
      return 0
    },
    GENERATED_ID () {
      return this.dialogTrx.data ? parseInt(this.dialogTrx.data.id) ? ('HLF/' + parseInt(this.dialogTrx.data.id)) : null : null
    },
    dateRangeText () {
      return this.dates.join(' ~ ')
    }
  },
  watch: {
    cart (v) {
      this.CART_SYNC()
    },
    'dialogTrx.data.payment_type' (v) {
      this.CART_SYNC()
    },
    areaquery (v) {
      if (!v) {
        return
      }
      const findSelectedArea = this.areas.find(r => r.area_name === v)
      if (findSelectedArea) {
        return
      }
      if (searchAreaTimeout) {
        clearTimeout(searchAreaTimeout)
      }
      searchAreaTimeout = setTimeout(() => {
        this.areaGet('?q=' + v)
        clearTimeout(searchAreaTimeout)
      }, 300)
    },
    customerAddressSelected (v) {
      if (v && this.dialogTrx.data.shipping_method === 'courier') {
        this.SHIPPING_CHECK(parseInt(v.kecamatan_id))
      }
    },
    'dialogTrx.data.shipping_method_courier' () {
      const v = this.customerAddressSelected
      if (v) {
        this.SHIPPING_CHECK(parseInt(v.kecamatan_id))
      }
    },
    'dialogTrx.data.shipping_method' (v) {
      this.customerShippingSelected = null
      this.customerShippingList = []
      if (v === 'courier' && this.customerAddressSelected && this.dialogTrx.data.shipping_method_courier === 'jne') {
        this.SHIPPING_CHECK(parseInt(this.customerAddressSelected.kecamatan_id))
      }
    },
    dates () {
      this.TRX_GET(this.tableSearch)
    },
    options: {
      handler () {
        this.TRX_GET(this.tableSearch)
      },
      deep: true
    },
    tableSearch: function (q) {
      if (searchInterval) {
        clearTimeout(searchInterval)
      }
      searchInterval = setTimeout(() => {
        this.TRX_GET(q)
      }, 300)
    },
    tableSearchPayment () {
      this.TRX_GET(this.tableSearch)
    },
    tableSearchScheduleType () {
      this.TRX_GET(this.tableSearch)
    },
    tableSearchStatus () {
      this.TRX_GET(this.tableSearch)
    },
    storeActive (v) {
      if (v) {
        this.TRX_GET(this.tableSearch)
      }
    },
    'items.selected' (v) {
      if (v) {
        if (v.id) {
          const customerType = this.customer.selected ? parseInt(this.customer.selected.type) || 0 : 0
          const itemExist = this.cart.find(r => parseInt(r.product_spec) === parseInt(v.id))
          if (itemExist) {
            this.$store.dispatch('TOAST', { show: true, message: 'Item sudah berada di list!' })
          } else {
            const newItem = Object.assign({}, v)
            newItem.id = null
            newItem.quantity = 1
            newItem.product = v.product
            newItem.product_spec = v.id
            newItem.price_berjenjang = typeof newItem.price_berjenjang === 'string' ? this.$StrToArray(newItem.price_berjenjang) : newItem.price_berjenjang
            const priceBerjenjang = this.$priceCounter(newItem.price_berjenjang, newItem.price, newItem.quantity, customerType, 0)
            newItem.priceActive = priceBerjenjang.price
            newItem.priceActiveData = priceBerjenjang.data
            this.cart.push(newItem)
            this.items.selected = null
            this.items.query = ''
          }
        }
      }
    },
    'items.query' (v, vBefore) {
      this.items.result = []
      if (v) {
        if (searchInterval) {
          clearTimeout(searchInterval)
        }
        searchInterval = setTimeout(() => {
          this.PRODUCT_GET(v)
        }, 300)
      }
    },
    'customer.selected' (v) {
      this.customerAddress = []
      this.areas = []
      this.areaquery = ''
      this.customerAddressSelected = null
      if (v) {
        if (v.id) {
          this.items.query = ''
          this.CART_SYNC(parseInt(v.type) || 0)
        } else {
          this.CART_SYNC(0)
        }
        this.LOAD_CUSTOMER_ADDRESS(v.id)
      } else {
        this.CART_SYNC(0)
      }
    },
    'customer.query' (v) {
      if (v) {
        this.customer.result = []
        if (searchInterval) {
          clearTimeout(searchInterval)
        }
        searchInterval = setTimeout(() => {
          this.CUSTOMER_GET(v)
        }, 300)
      }
    }
  },
  mounted () {
    this.dates = [this.$localDT(new Date(), 'datedefault'), this.$localDT(new Date(), 'datedefault')]
    this.TRX_GET()
  },
  methods: {
    SYNC_TOTAL () {
      if (this.TRXEDITED && this.dialogTrx.data.payment_type !== 'hutang') {
        this.dialogTrx.data.cash = (this.$total(this.cart, this.customer.selected) + this.COUNT_ONGKIR)
        this.dialogTrx.data.cash_refund = 0
      } else if (this.TRXEDITED && this.dialogTrx.data.payment_type === 'hutang') {
        this.dialogTrx.data.cash = 0
        this.dialogTrx.data.cash_refund = (this.$total(this.cart, this.customer.selected) + this.COUNT_ONGKIR)
      }
    },
    XenditPaymentParse (payment) {
      if (payment.notes) {
        if (payment.notes.match(/FAILED/gi)) {
          return {}
        } else {
          try {
            const json = JSON.parse(payment.notes)
            if (json.invoice_url) {
              return Object.assign(json, {
                status: true
              })
            }
          } catch {
            return {}
          }
        }
      }
      return {}
    },
    areaSync () {
      const id = parseInt(this.dialogAddress.data.kecamatan_id)
      if (id) {
        const findSelectedArea = this.areas.find(r => parseInt(r.kecamatan_id) === parseInt(id))
        if (findSelectedArea) {
          this.dialogAddress.data.city_id = parseInt(findSelectedArea.city_id) || 0
          this.dialogAddress.data.city = findSelectedArea.city_type + ' ' + findSelectedArea.city_name
          this.dialogAddress.data.kecamatan = findSelectedArea.kecamatan_name
        }
      }
    },
    areaGet (q, id) {
      this.areas = []
      this.$store.dispatch('AREA_GET', q + (parseInt(id) ? '&id=' + id : '')).then((res) => {
        if (res.status) {
          this.areas = res.data.data || []
        }
      })
    },
    AddAddress (a) {
      this.dialogAddress.data = null
      if (!this.customer.selected) {
        this.$store.dispatch('TOAST', { show: true, message: 'Terjadi kesalahan!' })
        return false
      }
      let obj = null
      if (a) {
        const r = this.customerAddressSelected
        obj = Object.assign({}, a)
        this.areaquery = r.kecamatan
        obj.kecamatan_id = r.kecamatan_id
        obj.city_id = r.city_id
      } else {
        this.customerAddressSelected = null
        obj = {
          id: null,
          customer: this.customer.selected.id || 0,
          name: '',
          phone: '',
          address: '',
          city: '',
          city_id: 0,
          kecamatan: '',
          kecamatan_id: 0,
          lat: '',
          lng: '',
          isdefault: 0
        }
      }
      this.dialogAddress.data = Object.assign({}, obj)
      this.dialogAddress.show = true
    },
    ProcessAddress () {
      const a = Object.assign({}, this.dialogAddress.data)
      a.customer = this.customer.selected ? parseInt(this.customer.selected.id) : 0
      if (!parseInt(a.customer)) {
        this.$store.dispatch('TOAST', { show: true, message: 'Terjadi kesalahan, undefined customer!' })
        return false
      }
      if (!a.name) {
        this.$store.dispatch('TOAST', { show: true, message: 'Nama penerima tidak boleh kosong!' })
        return false
      }
      if (!this.$validatePhone(a.phone)) {
        this.$store.dispatch('TOAST', { show: true, message: 'No HP tidak valid!' })
        return false
      }
      if (!a.address) {
        this.$store.dispatch('TOAST', { show: true, message: 'Alamat lengkap belum diisi!' })
        return false
      }
      if (!a.address) {
        this.$store.dispatch('TOAST', { show: true, message: 'Alamat lengkap belum diisi!' })
        return false
      }
      if (!parseInt(a.city_id)) {
        this.$store.dispatch('TOAST', { show: true, message: 'Kota belum dipilih!' })
        return false
      }
      if (!parseInt(a.kecamatan_id)) {
        this.$store.dispatch('TOAST', { show: true, message: 'Kecamatan belum dipilih!' })
        return false
      }
      this.loadingProcess = true
      this.$store.dispatch('customer/CUSTOMER_ADDRESS_PROCESS', a)
        .then((res) => {
          if (res.status) {
            if (res.data.data.id) {
              this.$store.dispatch('TOAST', { show: true, message: a.id ? 'Berhasil diupdate!' : 'Berhasil ditambah!' })
              this.LOAD_CUSTOMER_ADDRESS()
              this.customerAddressSelected = null
              this.dialogAddress.show = false
            } else {
              this.$store.dispatch('TOAST', { show: true, message: 'Terjadi Kesalahan!' })
            }
          } else {
            this.$store.dispatch('TOAST', { show: true, message: 'Terjadi Kesalahan!' })
          }
          this.loadingProcess = false
        })
    },
    PrintStruk () {
      window.print()
    },
    LoadDriver () {
      this.$store.dispatch('account/ACCOUNTS_GET', '?role=driver&store=' + (this.$store.state.storeActive || 0))
        .then((res) => {
          if (res.status) {
            this.drivers = res.data.data.data
            if (!this.drivers.length) {
              this.$store.dispatch('TOAST', { show: true, message: 'Belum ada akun driver!' })
            }
          } else {
            this.$store.dispatch('TOAST', { show: true, message: 'Terjadi kesalahan!' })
          }
        })
    },
    ProcessUpdateTrx (UpdateOnly) {
      this.dialogUpdateTrx.show = false
      const params = UpdateOnly ? { id: this.dialogTrx.data.id, shipping_driver_image: this.dialogTrx.data.shipping_driver_image, shipping_driver_notes: this.dialogTrx.data.shipping_driver_notes } : {
        id: this.dialogTrx.data.id,
        status: this.dialogUpdateTrx.status,
        shipping_awb: this.dialogUpdateTrx.shipping_awb,
        shipping_driver: this.dialogUpdateTrx.shipping_driver || 0
      }
      this.loadingProcess = true
      this.$store.dispatch('trx/TRX_UPDATE', params)
        .then((res) => {
          if (res.status) {
            this.TRX_GET(this.tableSearch)
            this.$store.dispatch('TOAST', { show: true, message: 'Berhasil diupdate!' })
            this.dialogUpdateTrx.show = false
            if (!UpdateOnly) {
              this.dialogTrx.show = false
            }
          } else {
            this.$store.dispatch('TOAST', { show: true, message: 'Terjadi kesalahan!' })
          }
          this.loadingProcess = false
        })
    },
    ProcessUpdateTrxPoin () {
      if (!confirm('Update Poin ?')) {
        return false
      }
      const params = {
        id: this.dialogTrx.data.id,
        point_used_price_100: this.dialogTrx.data.point_used_price_100,
        point_used: this.newPoinUpdate
      }
      this.loadingProcess = true
      this.$store.dispatch('trx/TRX_UPDATE', params)
        .then((res) => {
          if (res.status) {
            this.TRX_GET(this.tableSearch)
            this.dialogTrx.show = false
            this.$store.dispatch('TOAST', { show: true, message: 'Berhasil diupdate!' })
          } else {
            this.$store.dispatch('TOAST', { show: true, message: 'Terjadi kesalahan!' })
          }
          this.loadingProcess = false
        })
    },
    ShowUpdateTrxStatus () {
      if (this.dialogTrx.show && parseInt(this.dialogTrx.data.id)) {
        this.dialogUpdateTrx.status = this.dialogTrx.data.status
        this.dialogUpdateTrx.shipping_awb = this.dialogTrx.data.shipping_awb || ''
        this.dialogUpdateTrx.shipping_driver = 0
        this.dialogUpdateTrx.show = true
        if (this.dialogTrx.data.shipping_method === 'driver') {
          this.dialogUpdateTrx.shipping_driver = parseInt(this.dialogTrx.data.shipping_driver) || 0
          this.LoadDriver()
        }
      }
    },
    LOAD_CUSTOMER_ADDRESS (cid) {
      cid = cid || (this.customer.selected ? this.customer.selected.id : 0)
      this.customerAddress = []
      this.$store.dispatch('customer/CUSTOMER_ADDRESS', cid)
        .then((res) => {
          if (res.status) {
            const first = res.data.data.find(r => parseInt(r.isdefault))
            const newData = res.data.data.sort(function (x, y) { return x === first ? -1 : y === first ? 1 : 0 })
            this.customerAddress = newData || []
          } else {
            this.$store.dispatch('TOAST', { show: true, message: 'Terjadi kesalahan!' })
          }
        })
    },
    ShowCustomer () {
      this.newCustomer.data.id = null
      this.newCustomer.data.name = ''
      this.newCustomer.data.email = ''
      this.newCustomer.data.phone = ''
      this.newCustomer.data.type = 0
      this.newCustomer.show = true
    },
    ProcessCustomer () {
      const newData = Object.assign({}, this.newCustomer.data)
      if (!this.$validateName(newData.name)) {
        this.$store.dispatch('TOAST', { show: true, message: 'Masukan nama customer yang valid!' })
        return false
      }
      if (!this.$validateEmail(newData.email)) {
        this.$store.dispatch('TOAST', { show: true, message: 'Email customer tidak valid!' })
        return false
      }
      if (!this.$validatePhone(newData.phone)) {
        this.$store.dispatch('TOAST', { show: true, message: 'No HP customer tidak valid!' })
        return false
      }
      newData.phone = this.$reformatPhone(newData.phone)
      this.loadingProcess = true
      this.$store.dispatch('customer/CUSTOMER_PROCESS', newData)
        .then((res) => {
          if (res.status) {
            if (res.data.data.response) {
              this.newCustomer.show = false
              this.customer.selected = res.data.data.data
              this.$store.dispatch('TOAST', { show: true, message: 'Berhasil dibuat' })
            } else {
              this.$store.dispatch('TOAST', { show: true, message: res.data.data.message })
            }
          } else {
            this.$store.dispatch('TOAST', { show: true, message: 'Terjadi kesalahan!' })
          }
          this.loadingProcess = false
        })
    },
    TRX_GET (q) {
      this.tableSummary.quantity = 0
      this.tableSummary.subtotal = 0
      this.tableSummary.shipping = 0
      this.tableSummary.total = 0
      const fromTo = this.GetDates(this.dates)
      const schedultType = this.tableSearchScheduleType ? ('&schedule_type=' + (this.tableSearchScheduleType || '')) : ''
      const status = this.tableSearchStatus === 0 || this.tableSearchStatus > 0 ? this.tableSearchStatus : ''
      this.tableLoading = true
      const { sortBy, sortDesc, page, itemsPerPage } = this.options
      const query = '?c=' + ('&store=' + this.$store.state.storeActive) + (parseInt(page) > 0 ? '&page=' + page : '') + (parseInt(itemsPerPage) > 0 ? '&limit=' + parseInt(itemsPerPage) : '') + (sortBy.length ? '&sortby=' + sortBy[0] + (sortDesc[0] ? '&sort=DESC' : '&sort=ASC') : '')
      this.$store.dispatch('trx/TRX_GET', query + (q ? ('&q=' + q) : '') + (parseInt(status) === 0 || parseInt(status) > 0 ? '&status=' + status : '') + (fromTo) + (schedultType)).then((res) => {
        if (res.status) {
          this.table = res.data.data.data
          this.tableTotal = parseInt(res.data.data.total) || 0

          this.tableSummary.quantity = parseInt(res.data.data.summary.quantity) || 0
          this.tableSummary.subtotal = parseInt(res.data.data.summary.subtotal) || 0
          this.tableSummary.shipping = parseInt(res.data.data.summary.shipping) || 0
          this.tableSummary.total = parseInt(res.data.data.summary.total) || 0
        } else {
          this.table = []
          this.tableTotal = 0
          this.tableSummary.quantity = 0
          this.tableSummary.subtotal = 0
          this.tableSummary.shipping = 0
          this.tableSummary.total = 0
        }
        this.tableLoading = false
      })
    },
    LOAD_TRX_PAYMENT (trxID) {
      this.trxPayments = []
      this.$store.dispatch('trx/TRX_PAYMENT_GET', trxID)
        .then((res) => {
          if (res.status) {
            let sumTotal = 0
            const data = res.data.data.map((r) => {
              if (parseInt(r.status)) {
                sumTotal += parseInt(r.amount)
              }
              return r
            })
            this.dialogTrx.data.paid = sumTotal || 0
            this.trxPayments = data || []
          }
        })
    },
    TRX_PAYMENT_SHOW (p) {
      if (p) {
        this.dialogPayment.data = Object.assign({}, p)
      } else {
        this.dialogPayment.data = {
          id: null,
          payment_type: this.dialogTrx.data.payment_type,
          trx: this.dialogTrx.data.id,
          customer: this.dialogTrx.data.customer,
          amount: parseInt(this.dialogTrx.data.amount_total) - parseInt(this.dialogTrx.data.paid) || 0,
          notes: '',
          status: 0
        }
      }
      this.dialogPayment.show = true
    },
    TRX_PAYMENT_DELETE (pid) {
      if (!confirm('Delete Payment ?')) {
        return false
      }
      this.$store.dispatch('trx/TRX_PAYMENT_DELETE', pid)
        .then((res) => {
          if (res.status) {
            this.LOAD_TRX_PAYMENT(this.dialogTrx.data.id)
            this.$store.dispatch('TOAST', { show: true, message: 'Deleted!' })
            this.dialogPayment.show = false
          } else {
            this.$store.dispatch('TOAST', { show: true, message: 'Something went wrong!' })
          }
        })
    },
    TRX_PAYMENT_PROCESS () {
      if (this.dialogPayment.data) {
        if (!parseInt(this.dialogPayment.data.amount)) {
          this.$store.dispatch('TOAST', { show: true, message: 'Invalid Amount!' })
          return false
        }
      } else {
        this.$store.dispatch('TOAST', { show: true, message: 'Invalid Transaction!' })
        return false
      }
      this.loadingProcess = true
      this.$store.dispatch('trx/TRX_PAYMENT_PROCESS', this.dialogPayment.data).then((res) => {
        if (res.status) {
          this.LOAD_TRX_PAYMENT(this.dialogTrx.data.id)
          this.$store.dispatch('TOAST', { show: true, message: this.dialogTrx.data.id ? 'Berhasil diupdate!' : 'Berhasil disimpan!' })
          this.dialogPayment.show = false
        } else {
          this.$store.dispatch('TOAST', { show: true, message: 'Terjadi kesalahan!' })
        }
        this.loadingProcess = false
      })
    },
    TRX_SHOW (trx) {
      this.newPoinUpdate = 0
      if (trx) {
        this.LOAD_TRX_PAYMENT(trx.id)
      }
      this.cart = []
      this.customerAddress = []
      this.customer.selected = null
      this.customerAddressSelected = null
      this.dialogUpdateTrx.show = false
      if (trx) {
        this.newPoinUpdate = parseInt(trx.point_used || 0)
        this.$store.dispatch('trx/TRX_GET_ITEMS', trx.id)
          .then((res) => {
            if (res.status) {
              const d = res.data.data
              this.cart = (d || []).map(r => {
                if (r.trx_price_option) {
                  r.trx_price_option = this.$StrToJson(r.trx_price_option)
                }
                return r
              })
            }
          })
        this.dialogTrx.data = Object.assign({}, trx)
        if (parseInt(trx.customer) && trx.customer_name) {
          this.customer.query = trx.customer_name
        } else {
          this.customer.selected = null
        }
      } else {
        this.dialogTrx.data = Object.assign({}, newTrx)
        this.dialogTrx.data.store = parseInt(this.$store.state.storeActive) || 0
        this.dialogTrx.data.cashier = parseInt(this.$store.state.user.id) || 0
        this.dialogTrx.data.platform = 0
      }
      this.dialogTrx.show = true
    },
    TRX_PROCESS () {
      const trx = Object.assign({}, this.dialogTrx.data)
      if (trx.payment_type === 'online' && !parseInt(trx.cash)) {
        this.$store.dispatch('TOAST', { show: true, message: 'Pembayaran Hutang atau 0 tidak bisa memakai type Online!' })
        return false
      }
      trx.platform = 0
      trx.store = parseInt(this.$store.state.storeActive) || 0
      trx.cashier = parseInt(this.$store.state.user.id) || 0
      trx.customer = this.customer.selected ? (parseInt(this.customer.selected.id) || 0) : 0
      trx.payment_status = parseInt(trx.cash) === 0 ? 0 : (parseInt(trx.cash_refund) < 0 ? 2 : 1)
      if (!this.cart.length) {
        this.$store.dispatch('TOAST', { show: true, message: 'Belum ada barang dipilih!' })
        return false
      }
      if (trx.shipping_method === 'driver' || trx.shipping_method === 'courier') {
        if (this.customerAddressSelected) {
          trx.shipping_name = this.customerAddressSelected.name
          trx.shipping_phone = this.customerAddressSelected.phone
          trx.shipping_address = this.customerAddressSelected.address
          trx.shipping_postalcode = ''
          trx.shipping_city = this.customerAddressSelected.kecamatan + ', ' + this.customerAddressSelected.city
          trx.shipping_coordinate_lat = this.customerAddressSelected.lat || ''
          trx.shipping_coordinate_lng = this.customerAddressSelected.lng || ''
        } else {
          this.$store.dispatch('TOAST', { show: true, message: 'Alamat pengiriman belum ditentukan!' })
          return false
        }
      }
      if (trx.shipping_method === 'courier') {
        if (!trx.shipping_method_courier) {
          this.$store.dispatch('TOAST', { show: true, message: 'Kurir belum dipilih!' })
          return false
        }
        if (this.customerShippingSelected) {
          trx.shipping_method_type = typeof this.customerShippingSelected === 'object' ? JSON.stringify(this.customerShippingSelected) : this.customerShippingSelected
        } else {
          this.$store.dispatch('TOAST', { show: true, message: 'Metode pengiriman belum dipilih!' })
          return false
        }
      }
      // if (!parseInt(trx.cash)) {
      //   this.$store.dispatch('TOAST', { show: true, message: 'Nominal pembayaran belum di isi!' })
      //   return false
      // }
      if (trx.payment_type !== 'cash' && trx.payment_type !== 'cod' && !trx.payment_description) {
        this.$store.dispatch('TOAST', { show: true, message: 'Payment info belum di isi!' })
        return false
      }
      if (!confirm('Proses order ?')) {
        return false
      }

      const trxData = {
        items: this.cart,
        trx: trx
      }
      this.loadingProcess = true
      this.$store.dispatch('trx/TRX_PROCESS', trxData).then((res) => {
        if (res.status) {
          if (res.data.data.status) {
            this.TRX_GET(this.tableSearch)
            this.$store.dispatch('TOAST', { show: true, message: 'Berhasil dibuat!' })
            this.dialogTrx.show = false
          } else {
            this.$store.dispatch('TOAST', { show: true, message: 'Terjadi kesalahan!' })
          }
        } else {
          this.$store.dispatch('TOAST', { show: true, message: 'Terjadi kesalahan!' })
        }
        this.loadingProcess = false
      })
    },
    PRODUCT_GET (q) {
      this.$store.dispatch('product/PRODUCT_GET', ('?store=' + this.$store.state.storeActive) + '&q=' + (q || '') + '&page=1&limit=12&status=1').then((res) => {
        if (res.status) {
          this.items.result = res.data.data.data || []
        } else {
          this.items.result = []
        }
      })
    },
    CUSTOMER_GET (q) {
      this.$store.dispatch('customer/CUSTOMER_GET', ('?q=' + (q || ''))).then((res) => {
        if (res.status) {
          if (this.dialogTrx.data) {
            if (parseInt(this.dialogTrx.data.id) && parseInt(this.dialogTrx.data.customer)) {
              const c = Object.assign(res.data.data.find(r => parseInt(r.id) === parseInt(this.dialogTrx.data.customer)))
              if (c) {
                c.type = parseInt(this.dialogTrx.data.customer_type)
                this.customer.selected = c
              }
              return false
            }
          }
          this.customer.result = res.data.data || []
        } else {
          this.customer.result = []
        }
      })
    },
    CART_QUANTITY (act, index) {
      if (act === 'min') {
        if (this.cart[index].quantity > 2) {
          this.cart[index].quantity = (parseInt(this.cart[index].quantity) - 1)
        } else {
          this.cart[index].quantity = 1
        }
      } else if (act === 'plus') {
        this.cart[index].quantity += 1
      }
      this.CART_SYNC()
    },
    CART_SYNC () {
      const customerType = this.customer.selected ? parseInt(this.customer.selected.type) || 0 : 0
      if (this.TRXEDITED) {
        for (let c = 0; c < this.cart.length; c++) {
          const item = Object.assign(this.cart[c])
          const p = typeof item.price_berjenjang === 'string' ? this.$StrToArray(item.price_berjenjang) : item.price_berjenjang
          const priceBerjenjang = this.$priceCounter(p, item.price, item.quantity, customerType, 0)
          this.cart[c].priceActive = priceBerjenjang.price
          this.cart[c].priceActiveData = priceBerjenjang.data
        }
        this.SYNC_TOTAL()
      }
    },
    SHIPPING_CHECK (kecamatanID) {
      this.customerShippingSelected = null
      this.customerShippingList = []
      if (!parseInt(kecamatanID) || isNaN(parseInt(kecamatanID))) {
        this.$store.dispatch('TOAST', { show: true, message: 'Invalie selected address!' })
        return false
      }
      const selectedStore = this.$store.state.storeData.find(r => parseInt(r.id) === parseInt(this.storeActive))
      if (!selectedStore) {
        this.$store.dispatch('TOAST', { show: true, message: 'Lokasi toko gagal termuat!' })
        return false
      } else if (!parseInt(selectedStore.kecamatan_id)) {
        this.$store.dispatch('TOAST', { show: true, message: 'Asal toko belum ditentukan!' })
        return false
      }
      this.$store.dispatch('shipping/SHIPPING_CHECK', {
        kecamatan_from: selectedStore.kecamatan_id,
        kecamatan_to: kecamatanID,
        courier: this.dialogTrx.data.shipping_method_courier
      }).then((res) => {
        if (res.status) {
          if (res.data.data.resource === 'local' || res.data.data.resource === 'external') {
            if (res.data.data.resource === 'external') {
              this.SHIPPING_CHECK(kecamatanID)
            } else if (res.data.data.resource === 'local') {
              const d = res.data.data.data
              this.customerShippingList = d
              if (d.length) {
                this.customerShippingSelected = d[0]
              }
            } else {
              this.customerShippingList = []
            }
          }
        }
      })
    },
    GetDates () {
      const fromTo = this.dates
      if (fromTo) {
        if (fromTo.length === 2) {
          if ((new Date(fromTo[0])).getTime() < (new Date(fromTo[1])).getTime()) {
            return '&from=' + fromTo[0] + '&to=' + fromTo[1]
          } else {
            return '&from=' + fromTo[1] + '&to=' + fromTo[0]
          }
        }
      }
      return ''
    },
    GetFromTo (fromTo) {
      if (fromTo) {
        if (fromTo.length === 2) {
          if ((new Date(fromTo[0])).getTime() < (new Date(fromTo[1])).getTime()) {
            return ('&from=' + fromTo[0] + '&to=' + fromTo[1])
          } else {
            return (this.search, '&from=' + fromTo[1] + '&to=' + fromTo[0])
          }
        }
      }
      return ''
    },
    UploadImage () {
      if (!this.loadingImageUpload) {
        let u = document.getElementById('file-uploader')
        if (u) {
          u.remove()
        }
        setTimeout(() => {
          u = document.createElement('input')
          u.setAttribute('type', 'file')
          u.setAttribute('id', 'file-uploader')
          u.setAttribute('class', 'file-uploader')
          u.setAttribute('accept', '.png,.gif,.jpeg,.jpg')
          u.addEventListener('change', (e) => {
            this.uploadPhotoRender(e)
          })
          document.body.appendChild(u)
          u.click()
        }, 100)
      }
    },
    async uploadPhotoRender (event) {
      /* eslint-disable */
      const $ = this
      await $.$filetoBase64(event, async function (res) {
        if (res.status) {
          $.loadingImageUpload = true
          await $.$store.dispatch('UPLOAD_FILE', {
            file: res.data,
            source: 'shipping'
          }).then((resUpload) => {
            if (resUpload.status) {
              if (resUpload.data.data.res) {
                $.dialogTrx.data.shipping_driver_image = resUpload.data.data.secure_url
                $.ProcessUpdateTrx($.dialogTrx.data.id)
              } else {
                $.$store.dispatch('TOAST', { show: true, message: 'Gagal upload, silahkan coba lagi!' })
              }
            }
            $.loadingImageUpload = false
          })
        } else {
          res.data === 'file_size'
            ? $.$store.dispatch('TOAST', { show: true, message: 'Image size no more than 1Mb' }) // ? $.$store.commit('TOAST', { show: true, message: 'Ops, file image is too large. Max size (files < 5Mb & video < 20Mb)' })
            : res.data === 'canceled'
              ? console.log('Upload canceled.')
              : console.log(res.data)
        }
      })
    }
  }
}
</script>

<style lang="scss">
.barcode-class {
  display: flex;
  justify-content: center;
  svg {
    display: block;
  }
}
@media screen {
  // .dialog-printed {
  //   display: none;
  // }
}

@media print {
  html {
    &.overflow-y-hidden {
      overflow: auto!important;
    }
  }
  body {
    * {
      &:not(.v-application) {
        visibility:hidden;
      }
    }
  }
  .v-application {
    width:100%!important;
    >div {
      &:not([style="z-index: 204;"]) {
        display: none!important;
      }
    }
  }
  .container--fluid {
    height:auto!important;
  }
  .v-dialog__content {
    height: auto!important;
    max-height: unset!important;
    width: 100%!important;
    position: relative!important;
    align-items: flex-start!important;
    justify-content: start!important;
    .v-dialog {
      &:not(.v-dialog--fullscreen) {
        max-height: unset!important;
        &.dialog-printed {
          visibility:hidden!important;
          border-radius: 0px!important;
          margin: 0px!important;
          width:100%!important;
          >.v-card,
          >.v-card * {
            visibility: visible;
            overflow: visible !important;
          }
          >.v-card {
            box-shadow: none!important;
            position:absolute;
            left:0;
            top:0;
          }
          .no-print {
            display: none!important;
            visibility:hidden;
          }
        }
      }
    }
  }
}
</style>
